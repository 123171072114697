(function () {
  function r(e, n, t) {
    function o(i, f) {
      if (!n[i]) {
        if (!e[i]) {
          var c = "function" == typeof require && require;
          if (!f && c) return c(i, !0);
          if (u) return u(i, !0);
          var a = new Error("Cannot find module '" + i + "'");
          throw a.code = "MODULE_NOT_FOUND", a;
        }

        var p = n[i] = {
          exports: {}
        };
        e[i][0].call(p.exports, function (r) {
          var n = e[i][1][r];
          return o(n || r);
        }, p, p.exports, r, e, n, t);
      }

      return n[i].exports;
    }

    for (var u = "function" == typeof require && require, i = 0; i < t.length; i++) o(t[i]);

    return o;
  }

  return r;
})()({
  1: [function (require, module, exports) {
    'use strict';

    module.exports = {
      toggleMenu: function () {
        $('.menu-bar').on('click', function () {
          $('.nav').toggleClass('menu-active', 2500);
        });
      },
      imageSlider: function () {
        let $slider = $('.image-slider');

        if ($slider !== undefined && $slider.length) {
          $slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            swipe: true,
            dots: false,
            fade: true,
            arrows: false,
            autoplay: false,
            speed: 500
          });
          $('.image-slider-nav').slick({
            slidesToShow: 5,
            swipeToSlide: true,
            asNavFor: $slider,
            arrows: false,
            dots: false,
            centerMode: true,
            focusOnSelect: true,
            responsive: [{
              breakpoint: 1024,
              settings: {
                slidesToShow: 3
              }
            }, {
              breakpoint: 768,
              settings: {
                slidesToShow: 2
              }
            }, {
              breakpoint: 768,
              settings: {
                slidesToShow: 2
              }
            }, {
              breakpoint: 450,
              settings: {
                slidesToShow: 1
              }
            }]
          });
        }
      },
      ajaxPostToMail: function () {
        let form = $('#contact-form');
        let NotifySuccess = $('#notification-success-message');
        let NotifyError = $('#notification-error-message');
        let NotifyOpen = 'notification-open';
        $(form).submit(function (eventSubmit) {
          eventSubmit.preventDefault();
          let formData = $(form).serialize();
          $.ajax({
            type: 'POST',
            url: $(form).attr('action'),
            data: formData
          }).done(function () {
            $(NotifyError).removeClass(NotifyOpen);
            $(NotifySuccess).addClass(NotifyOpen);
            $(form).find('input, textarea').val('');
          }).fail(function () {
            $(NotifySuccess).removeClass(NotifyOpen);
            $(NotifyError).addClass(NotifyOpen);
          });
        });
      },
      smoothScroll: function () {
        $('.nav ul li a').on('click', function (eventScroll) {
          if (this.hash !== '') {
            eventScroll.preventDefault();
            let hash = this.hash;
            $('html, body').animate({
              scrollTop: $(hash).offset().top
            }, 1500, function () {
              window.location.hash = hash;
            });
          }
        });
      }
    };
  }, {}],
  2: [function (require, module, exports) {
    'use strict';

    const {
      toggleMenu
    } = require('./functions');

    const functions = require('./functions');

    const app = {
      init: function () {
        if (('ontouchstart' in window || navigator.msMaxTouchPoints > 0) && window.matchMedia('screen and (max-width: 1024px)').matches) {
          $('html').addClass('touch');
        } else {
          $('html').addClass('no-touch');
        }

        functions.toggleMenu();
        functions.imageSlider();
        functions.ajaxPostToMail();
        functions.smoothScroll();
      }
    };
    $(function () {
      app.init();
    });
  }, {
    "./functions": 1
  }]
}, {}, [2]);